import { useState } from 'react';
import './Main.css';

import imgSrc from '../../assets/img/line-t.png';

function Main() {
  const [loaded, setLoaded] = useState<boolean>(false);

  const onload = function () {
    setLoaded(true);
  };

  return (
    <main>
      {loaded ? (
        <div className="rainbow-bg">
          <div className="blur-pulse" id="line-face"></div>
        </div>
      ) : (
        <img src={imgSrc} onLoad={onload} alt="Loading" />
      )}
    </main>
  );
}

export default Main;
