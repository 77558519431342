import React, { FunctionComponent, useContext, useMemo, useState } from 'react';
import './pieces/default.css';
import './pieces/neon.css';
import './pieces/transition.css';

export type ArtContextType = {
    piece?: Piece;
    setter: (s: string) => void;
};

type Piece = {
    selector?: string;
}

export const pieces: { [name: string]: Piece} = {
    default: {
        selector: 'default',
    },
    // neon: {
    //     selector: 'neon',
    // },
}

const defaultContext = { piece: pieces.default, setter: () => {} };

const ArtContext = React.createContext<ArtContextType>(defaultContext);

export const ArtRoll: FunctionComponent = () => {
    const artContext = useContext(ArtContext);
    const otherPieces = Object.keys(pieces).filter(key => key!== artContext.piece?.selector);
    const nextArt = otherPieces[Math.floor(Math.random() * otherPieces.length)];

    if (!nextArt) {
        return <></>;
    }
    return (
        <button onClick={() => artContext.setter(nextArt)}>
            {nextArt}
        </button>
    )
};

export const ArtPage: FunctionComponent = ({ children }) => {
    const [piece, setPiece] = useState<Piece>(pieces.default);
    const [transit, setTransit] = useState<string>('');
    const context = useMemo(() => ({
        piece,
        setter: (s: string) => { 
            setTransit('transition');
            setTimeout(() => setPiece(pieces[s] ?? pieces.default), 250);
            setTimeout(() => setTransit(''), 500);
        }
    }), [piece, setPiece]);
    return (
        <ArtProvider value={context}>
            <div className={`${piece.selector} ${transit}`}>
                {children}
            </div>
        </ArtProvider>);
};

export const ArtProvider = ArtContext.Provider;
export default ArtContext;