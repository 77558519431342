import React from 'react';

import {ArtPage} from './context/Art';
import Footer from './layout/footer/Footer';
import Header from './layout/header/Header';
import Main from './layout/main/Main';
import './App.css';

function App() {
  return (
    <div className="App">
        <ArtPage>
          <Header />
          <Main />
          <Footer />
        </ArtPage>
    </div>
  );
}

export default App;
