import { ArtRoll } from '../../context/Art';

import './Header.css';

function Header() {
  return (
    <header>
      <h1 id="title">maxime</h1>
      <ArtRoll />
    </header>
  );
}

export default Header;
